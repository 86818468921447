<template>
  <div>
    <Label><strong v-if="required" class="required">*</strong>Relatórios</Label>
    <CRow>
      <CCol sm="2" class="no-padding-left">
        <CInput v-model="recordFind.nr_sequencia" readonly></CInput>
      </CCol>
      <CCol sm="10" class="no-padding-right">      
      <CInput label="" v-model="recordFind.ds_titulo" :readonly="readonly">
        <template #append>
          <CButton v-if="findKey !== null" color="light" :disabled="readonly" @click="clear">
            <i class="fas fa-times"></i>
          </CButton>
          <CButton color="light" :disabled="readonly" @click="showModal = true">
            <i class="fas fa-search"></i>
          </CButton>
        </template>
      </CInput>
      </CCol>
    </CRow>

    <!-- Modal Component -->
    <CModal size="xl" title="Find Report" :show.sync="showModal">
      <CRow>
        <CCol sm="4">
          <Filtrate @filter-report="filter = $event"></Filtrate>
        </CCol>
        <CCol sm="8">
          <!-- GRID -->
          <Grid
            :isFind="true"
            :fields="fields"
            :items="items"
            @row-find-selected="rowFindSelected = $event"
            class="ml-1"
          ></Grid>
        </CCol>
      </CRow>

      <template #footer>
        <CButton size="sm" class="bp-btn-toolbars mr-2" @click="showModal = false">
          <i class="fa fa-undo mx-2"></i> Cancelar
        </CButton>
      </template>
    </CModal>
  </div>
</template>
<script>
import fn from "@/js";

import Filtrate from "./ReportFilter";
import Grid from "@/components/FormGrid";

export default {
  name: "ReportFind",
  props: {
    required: Boolean,
    findKey: null,
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  components: { Filtrate, Grid },
  computed: {},
  data() {
    return {
      //Variaveis Fixas
      COMPONENT_FIND: {
        endPoint: "api/v01/origin/tasy/relatorio",
        primaryKey: "nr_sequencia",
        descriptionKey: "ds_titulo",
        valueKey: null,
      },
      showModal: false,
      filter: {},
      fields: [],
      items:[],
      recordFind: {},
      rowFindSelected: {}
    };
  },
  methods: {
    init(){
      fn.initializeFind(this)
    },
    clear() {
      this.recordFind = {}
      this.rowFindSelected = {}
      this.$emit("find-report", null)
    }
  },
  watch: {
    findKey: {
      immediate: true,
      handler(findKey) {
        if(findKey !== undefined && findKey !== null) {
          this.COMPONENT_FIND.valueKey = findKey;
          this.recordFind.nr_sequencia = findKey;
          fn.initializeFind(this);
        }        
      }
    },
    filter() {
      if (Object.entries(this.filter).length !== 0) {
        this.init()
        fn.getFindRecordsByFilters(this);
      }
    },
    rowFindSelected() {
      if (Object.entries(this.rowFindSelected).length !== 0) {
        this.$emit("find-report", this.rowFindSelected);
        fn.resetFind(this)
      }
    }
  }
};
</script>
