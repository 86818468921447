<template>
  <div class="px-2">
    <CRow>
      <CCol sm="12">
        <Toolbars @set-action-toolbars="toolbars = $event"></Toolbars>
      </CCol>
    </CRow>
    <CRow>
      <!--<CCol sm="4" v-if="showFilter">
        <Filtrate @filter-="filter = $event"></Filtrate>
      </CCol>-->
      <CCol :sm="getCol">
        <!-- GRID -->
        <Grid v-if="showGrid" :fields="fields" :items="items" @grid-row-selected="gridRowSelected = $event" @grid-dbclick="gridDbclick = $event"/>

        <!-- DETALHE -->
        <Detail v-else-if="!showGrid" :tracer="record">
          <template slot="default">
            <CRow>
              <CCol sm="6">
                <CInput
                  id="component_parameter_tenant_id"
                  readonly
                  v-model="record.component_parameter_tenant_id">
                  <template #prepend-content
                    ><i class="fas fa-info-circle"
                  /></template>
                </CInput>
              </CCol>
            </CRow>

            <CRow>
              <CCol sm="1" >
                <CInput
                  id="parameter_report_presentation"
                  label="Seq."
                  readonly
                  v-model="record.parameter_report_presentation"
                />
              </CCol>
               <CCol sm="2" >
                <CInput
                  id="parameter_report_type"
                  label="Tipo do Parâmetro"
                  readonly
                  v-model="record.parameter_report_type"
                />
              </CCol>
              <CCol sm="3" >
                <CInput
                  id="parameter_report_code"
                  label="Parâmetro"
                  readonly
                  v-model="record.parameter_report_code"
                />
              </CCol>
              <CCol sm="6" >
                <CInput
                  id="parameter_report_ds"
                  label="Label do Parâmetro"
                  readonly
                  v-model="record.parameter_report_ds"
                />
              </CCol>             
            </CRow>

            <CRow>
              <CCol sm="6">
                <CInput
                  id="parameter_default_value"
                  label="Valor Default"
                  :readonly="readOnly"
                  v-model="record.parameter_default_value"
                />
              </CCol>

              <CCol sm="6">
                <CSelect 
                  id="macro_id"
                  label="Macro"
                  :readonly="readOnly"
                  :options="macroOptions"
                  v-model="record.macro_id"
                  @update:value="record.macro_id = $event"/>
              </CCol>
            </CRow>
          </template>
        </Detail>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import fn from "@/js"

import Toolbars from "@/components/Toolbars";
import Grid from "@/components/FormGrid";
import Detail from "@/components/FormDetail";
//import Filtrate from "./ComponentParameterTrailFilter";

export default {
  name: "TrailReportParameter",
  props: {
    parent: Object,
  },
  components: { Toolbars, Grid, Detail, /*Filtrate*/ },
  computed: {
    showFilter() {
      return this.$store.state.showFilter;
    },
    switchesIcon() {
      return this.$store.state.switchesIcon;
    },
    getCol() {
      return this.showFilter === false ? 12 : 12;
    },
  },
  data() {
    return {
      //Variaveis Fixas
      COMPONENT: {
        endPoint: "api/v01/internal/trail-report-parameter",
        primaryKey: "trail_report_parameter_id",
        descriptionKey: "trail_report_parameter_id",
        config: {
          child: true,
          parentKey: "trail_report_id",
          parentValue: "trail_report_id",
          parentService: "find-all-by-report",
          delete: { method: "delete", atribute: null, label: null },
          allowCrud: true,
          fields: 'default',
        },
        finds: []
      },
      //Variaveis Mutantes
      showGrid: true,
      readOnly: false,
      toolbars: {},
      filter: {},
      record: {},
      gridRowSelected: {},
      gridDbclick: {},
      fields: [],
      items: [],

      //Variaveis Componente <Select>
      macroOptions: [],
    };
  },
  methods: {
    async init(){
      await this.$nextTick(() => {
        fn.initializeComponent(this)
        this.macroOptions = fn.finds.findMacros()
      })
      //Chamando metodo after após resoluções iniciais
      this.afterInit()
    },
    afterInit() {
      let param = {}
      param[`${this.COMPONENT.config.parentKey}`] = this.parent.parentValue
      fn.getRecordsByMethod(this, param, this.COMPONENT.config.parentService) 
    },
    setInitialValues() {     
      fn.setAction(this)
    },
  },
  watch: {
    toolbars: {
      immediate: true,
      handler(toolbars) {
        if(toolbars !== null && toolbars === 'add') {
          this.setInitialValues()
        } else if(toolbars !== null) {
          fn.setAction(this)
        }
      }
    },
    filter: {
      immediate: true,
      handler(filter) {
        if (Object.entries(filter).length !== 0) { 
          fn.getRecordsByFilters(this)    
        }
      }
    },
    gridRowSelected: {
      immediate: true,
      handler(gridRowSelected) {
        if (Object.entries(gridRowSelected).length !== 0) { 
          fn.loadRecord(this)    
        }
      }
    },
    parent: {
      immediate: true,
      handler(parent) {
        if(parent !== undefined && parent.parentKey === this.COMPONENT.config.parentKey) {
          this.init()
        } else {
          fn.reset(this)
        }
      }
    }
  }
}
</script>
