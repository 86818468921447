<template>
  <div>
    <FormFilter @set-action-filter="action = $event">
      <template slot="default">        
        <CInput id="nr_sequencia" label="ID" v-model="filter.nr_sequencia" />
        <CInput id="cd_classif_relat" label="Classificação" v-model="filter.cd_classif_relat" />
        <CInput id="cd_relatorio" label="Código" v-model="filter.cd_relatorio"/>
        <CInput id="ds_titulo" label="Nome do Relatório" v-model="filter.ds_titulo"/>
      </template>
    </FormFilter>
  </div>
</template>

<script>
import FormFilter from '@/components/FormFilter'

export default {
  name: 'ReportFilter',
  components: {FormFilter},
  data () {
    return {
      action: {},
      filter: {}
    }
  },
  methods: {
    apply() { 
      this.filter.apply = true
      this.triggerEvent()     
    },
    reset() {
      this.filter = {}
      this.filter.reset = true
      this.triggerEvent()   
    },
    triggerEvent() { 
      this.$emit('filter-report', this.filter);
      this.action = {}   
      if(this.filter.reset) this.filter = {}
    }
  },
  watch: {
    action() {
      if(this.action === 'applyFilter') {
        this.apply()     
      } else if(this.action === 'resetFilter') {
        this.reset()        
      }
    }
  }
}
</script>
