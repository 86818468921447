<template>
  <div>
    <h5 class="pb-2">Trilhas de Atendimento</h5>
    <div class="_main">
      <CCol sm="12" md="12" class="_no-padding">
        <CCard class="_card-container">
          <CCardHeader class="_card-header-parent mt-2" v-if="pages.length !== 0">
            <div>
              <strong class="mr-2">Trilha:</strong>                        
              <CButton v-for="(item, index) in pages" :key="index" size="sm" color="blue-dark" class="_btn-parent" @click="reset(index)">
                <i class="far fa-dot-circle mr-2"></i> {{item.descriptionKey}}
              </CButton>         
            </div>
          </CCardHeader>
          <CCardBody class="_card-body">
            <Trail v-if="pages.length === 0" @set-hierarchy-level="hierarchyLevel = $event"></Trail>
            <div class="_main-parent">
              <CTabs :active-tab.sync="activeTab" v-model="activeTab">
                <!-- Nivel 01 -->
                <CTab v-show="activeLevel===1">
                  <template slot="title">Componentes da Trilha</template>
                  <TrailComponent :parent="getLastPage" @set-hierarchy-level="hierarchyLevel = $event"></TrailComponent>                   
                </CTab>

                <CTab v-show="activeLevel===1">
                  <template slot="title">Relatorios da Trilha</template>
                  <TrailReport :parent="getLastPage" @set-hierarchy-level="hierarchyLevel = $event"></TrailReport>-
                </CTab>
                
                <!-- Nivel 02 -->
                <CTab v-show="activeLevel===2 && getLastPage.parentName === 'TrailComponent'">
                  <template slot="title">Parâmetros do Componente</template>
                  <ComponentParameterGO :parent="getLastPage" @set-hierarchy-level="hierarchyLevel = $event"></ComponentParameterGO>
                </CTab>
                
                <CTab v-show="activeLevel===2 && getLastPage.parentName === 'TrailComponent'">
                  <template slot="title">Eventos Relacionados</template>
                  <ComponentEventGO :parent="getLastPage" @set-hierarchy-level="hierarchyLevel = $event"></ComponentEventGO>
                </CTab>
                
                <CTab v-show="activeLevel===2 && getLastPage.parentName === 'TrailReport'">
                  <template slot="title">Parâmetros do Relatorios</template>                  
                  <TrailReportParameter :parent="getLastPage" @set-hierarchy-level="hierarchyLevel = $event"></TrailReportParameter>
                </CTab>
                
                <!-- Nivel 03 -->
                <CTab v-show="activeLevel===3 && getLastPage.parentName === 'ComponentParameterGO'">
                  <template slot="title">Parâmetros da Trilha</template>
                  <ComponentParameterTrail :parent="getLastPage" :trail="trailSelected"></ComponentParameterTrail>
                </CTab>
                
                <CTab v-show="activeLevel===3 && getLastPage.parentName === 'ComponentEventGO'">
                  <template slot="title">Regras do Evento </template>
                  <ComponentEventTrail :parent="getLastPage" :trail="trailSelected"></ComponentEventTrail>
                </CTab>
                
              </CTabs>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </div>
  </div>
</template>

<script>
import Trail from './Trail'
import TrailComponent from './component/TrailComponent'
import ComponentParameterGO from '@/modules/component/parameter/ComponentParameterGO'
import ComponentParameterTrail from '@/modules/component/parameter/trail/ComponentParameterTrail'

import ComponentEventGO from '@/modules/component/event/ComponentEventGO'
import ComponentEventTrail from '@/modules/component/event/trail/ComponentEventTrail'


import TrailReport from './report/TrailReport'
import TrailReportParameter from './report/parameter/TrailReportParameter'

export default {
  name: 'TrailContainer',
  components: {Trail, TrailComponent, ComponentParameterGO, ComponentParameterTrail, ComponentEventGO, ComponentEventTrail, TrailReport, TrailReportParameter },
  computed: {
    trailSelected() {
      if(this.pages.length > 0) {
        return this.getFirstPage.parentValue
      } else {
        return null 
      }
    },
    getFirstPage() {
      return this.pages[0];
    },    
    getLastPage() {
      return this.pages[this.pages.length-1];
    },
    activeLevel() {
      return this.pages.length
    }
  },
  data() {
    return {
      activeTab: 0,
      hierarchyLevel: {},
      pages: [],
      tabs:[
        {"parentName":"Trail","tabIndex":0},
        {"parentName":"TrailComponent","tabIndex":2},
        {"parentName":"TrailReport","tabIndex":4},
        {"parentName":"ComponentParameterGO","tabIndex":5},
        {"parentName":"ComponentEventGO","tabIndex":6}
      ]
    }
  },
  methods: {
    setTabIndex(payload){
      let tab = this.tabs.filter(i => (i.parentName === payload.parentName));
      this.activeTab = tab[0].tabIndex
    },
    reset(payload) {
      if(payload === 0) {
        this.pages = []
      } else {
        this.pages.splice(payload);
        this.setTabIndex(this.getLastPage)
      } 
    }
  },
  watch: {
    hierarchyLevel: {
      immediate: true,
      handler(hierarchyLevel) {
        if (Object.entries(this.hierarchyLevel).length !== 0) {
          this.pages.push(hierarchyLevel)
          this.setTabIndex(hierarchyLevel)
        }
      },
    },
  }
}
</script>
