<template>
  <div class="px-2">
    <CRow>
      <CCol :sm="getCol">
        <!-- GRID -->
        <Grid :isFind="true" :fields="fields" :items="items" @grid-dbclick="gridDbclick = $event"/>
      </CCol>
    </CRow>
  </div>
</template>

<script>

import fn from "@/js";
import Grid from "@/components/FormGrid";

export default {
  name: "ComponentParameterGO",
  props: {
    parent: Object,
  },
  components: { Grid },
  computed: {
    showFilter() {
      return this.$store.state.showFilter;
    },
    switchesIcon() {
      return this.$store.state.switchesIcon;
    },
    getCol() {
      return this.showFilter === false ? 12 : 12;
    },
  },
  data() {
    return {
      //Variaveis Fixas
      COMPONENT: {
        endPoint: "api/v01/internal/component-parameter",
        primaryKey: "component_parameter_id",
        descriptionKey: "parameter_description",
        config: {
          child: true,
          parentKey: "component_id",
          parentValue: "component_id",
          parentService: "find-all-by-component",
          delete: { method: "delete", atribute: null, label: null },
          allowCrud: false,
          fields: 'find',
        },
        finds: []
      },
      gridDbclick: {},
      //Variaveis Mutantes      
      fields: [],
      items: [],
    };
  },
  methods: {
    async init(){
      await this.$nextTick(() => {
        fn.initializeComponent(this)
      })
      //Chamando metodo after após resoluções iniciais
      this.afterInit()
    },
    afterInit() {
      fn.getRecords(this)
    },
  },
  watch: {
    gridDbclick() {
      if (Object.entries(this.gridDbclick).length !== 0) {
        this.items = []
        let obj = {}
        obj.descriptionKey = `${this.gridDbclick.parameter_number} - ${this.gridDbclick.parameter_description} `
        obj.parentName = this.$options.name
        obj.parentKey = this.COMPONENT.primaryKey
        obj.parentValue = this.gridDbclick.component_parameter_id
        obj.domain_id = this.gridDbclick.domain_id
        obj.default_value = this.gridDbclick._default_value
        this.$emit("set-hierarchy-level", obj);
      }
    },
    parent: {
      immediate: true,
      handler(parent) {
        if(parent !== undefined && parent.parentKey === this.COMPONENT.config.parentKey) {
          this.init()
        } else {
          fn.reset(this)
        }
      }
    },
  }
}
</script>
