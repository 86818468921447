<template>
  <div class="px-2">
    <CRow>
      <CCol sm="12">
        <Toolbars @set-action-toolbars="toolbars = $event"></Toolbars>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="4" v-if="showFilter">
        <Filtrate :componentOptions="componentOptions" @filter-trail-report="filter = $event"></Filtrate>
      </CCol>
      <CCol :sm="getCol">
        <!-- GRID -->
        <Grid v-if="showGrid" :fields="fields" :items="items" @grid-row-selected="gridRowSelected = $event" @grid-dbclick="gridDbclick = $event"/>

        <!-- DETALHE -->
        <Detail v-else-if="!showGrid" :tracer="record">
          <template slot="default">
            <CRow>
              <CCol sm="6">
                <CInput
                  id="trail_report_id"
                  readonly
                  v-model="record.trail_report_id">
                  <template #prepend-content
                    ><i class="fas fa-info-circle"
                  /></template>
                </CInput>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="9">
                <ReportFind
                  :required="true"
                  :findKey="report.nr_sequencia !== null ? report.nr_sequencia : record.report_id"
                  :readonly="readOnly"
                  @find-report="report = $event"
                ></ReportFind>
              </CCol>

              <CCol sm="3">
                <CInput
                  id="sequence_execution"
                  label="Ordem de Execução"
                  :readonly="readOnly"
                  v-model="record.sequence_execution"
                />
              </CCol>
            </CRow>

            <CRow>
              <CCol sm="6">
                <CInput
                  id="signature_position_x"
                  label="Posição Assinatura (Eixo X) "
                  :readonly="readOnly"
                  v-model="record.signature_position_x"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  id="signature_position_y"
                  label="Posição Assinatura (Eixo Y) "
                  :readonly="readOnly"
                  v-model="record.signature_position_y"
                />
              </CCol>
            </CRow>
          </template>
        </Detail>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import fn from "@/js"

import Toolbars from "@/components/Toolbars";
import Grid from "@/components/FormGrid";
import Detail from "@/components/FormDetail";
import Filtrate from "./TrailReportFilter";
import ReportFind from "@/modules/his/report/ReportFind";

export default {
  name: "TrailReport",
  props: {
    parent: Object,
  },
  components: { Toolbars, Grid, Detail, Filtrate, ReportFind },
  computed: {
    showFilter() {
      return this.$store.state.showFilter;
    },
    switchesIcon() {
      return this.$store.state.switchesIcon;
    },
    getCol() {
      return this.showFilter === false ? 12 : 8;
    },
  },
  data() {
    return {
      //Variaveis Fixas
      COMPONENT: {
        endPoint: "api/v01/internal/trail-report",
        primaryKey: "trail_report_id",
        descriptionKey: "trail_report_id",
        config: {
          child: true,
          parentKey: "trail_id",
          parentValue: "trail_id",
          parentService: "find-all-by-trail",
          delete: { method: "delete", atribute: null, label: null },
          allowCrud: true,
          fields: 'default',
        },
        finds: []
      },
      //Variaveis Mutantes
      showGrid: true,
      readOnly: false,
      toolbars: {},
      filter: {},
      record: {},
      gridRowSelected: {},
      gridDbclick: {},
      fields: [],
      items: [],

      //Variaveis Componente <Select>
      componentOptions: [],

      //Variaveis Finds
      report: {}
    };
  },
  methods: {
    async init(){
      await this.$nextTick(() => {
        fn.initializeComponent(this)
        this.componentOptions = fn.finds.findAllComponent();
      })
      //Chamando metodo after após resoluções iniciais
      this.afterInit()
    },
    afterInit() {
      fn.getRecords(this)
    },
    setInitialValues() {     
      this.record.sequence_execution = this.items.length + 1 
      fn.setAction(this)
    },
  },
  watch: {
    toolbars: {
      immediate: true,
      handler(toolbars) {
        if(toolbars !== null && toolbars === 'add') {
          this.setInitialValues()
        } else if(toolbars !== null) {
          fn.setAction(this)
        }
      }
    },
    filter: {
      immediate: true,
      handler(filter) {
        if (Object.entries(filter).length !== 0) { 
          fn.getRecordsByFilters(this)    
        }
      }
    },
    gridRowSelected: {
      immediate: true,
      handler(gridRowSelected) {
        if (Object.entries(gridRowSelected).length !== 0) { 
          fn.loadRecord(this)    
        }
      }
    },
    gridDbclick() {
      if (Object.entries(this.gridDbclick).length !== 0) {
        this.items = []
        let obj = {}
        obj.descriptionKey = `${this.gridDbclick.report_title}`
        obj.parentName = this.$options.name
        obj.parentKey = this.COMPONENT.primaryKey
        obj.parentValue = this.gridDbclick[`${this.COMPONENT.primaryKey}`]
        this.$emit("set-hierarchy-level", obj);
      }
    },
    parent: {
      immediate: true,
      handler(parent) {   
        if(parent !== undefined && parent.parentKey === this.COMPONENT.config.parentKey) {
          this.init()
        } else {
          fn.reset(this)
        }
      }
    },
    record: {
      immediate: true,
      handler(record) {
        if(record !== undefined) {
          this.report.nr_sequencia = record.report_id
        }
      }
    },
    report:{
      immediate: true,
      handler(report){
        if (report.nr_sequencia !== null && report.nr_sequencia !== undefined) {
          this.record.report_id = report.nr_sequencia
          this.record.report_classification_ds = report.cd_classif_relat
          this.record.report_code_ds = report.cd_relatorio
          this.record.report_title = report.ds_titulo
        }
      }
    }

  }
}
</script>
