<template>
  <div class="px-2">
    <CRow>
      <CCol sm="12">
        <Toolbars @set-action-toolbars="toolbars = $event"></Toolbars>
      </CCol>
    </CRow>
    <CRow>
      <!--<CCol sm="4" v-if="showFilter">
        <Filtrate @filter-="filter = $event"></Filtrate>
      </CCol>-->
      <CCol :sm="getCol">
        <!-- GRID -->
        <Grid v-if="showGrid" :fields="fields" :items="items" @grid-row-selected="gridRowSelected = $event" @grid-dbclick="gridDbclick = $event"/>

        <!-- DETALHE -->
        <Detail v-else-if="!showGrid" :tracer="record">
          <template slot="default">
            <CRow>
              <CCol sm="6">
                <CInput
                  id="component_parameter_trail_id"
                  readonly
                  v-model="record.component_parameter_trail_id">
                  <template #prepend-content
                    ><i class="fas fa-info-circle"
                  /></template>
                </CInput>
              </CCol>
            </CRow>
            <!-- Conforme Domain -->
            <CRow v-if="parent.domain_id !== null">
              <CCol sm="12">
                <CInput
                  id="default_value"
                  label="Valor Default"
                  readonly
                  v-model="parent.default_value"
                />
              </CCol>  
              <CCol sm="12" >
                <CSelect
                  id="parameter_value"
                  label="Valor Cliente"
                  :readonly="readOnly"
                  :options="defaultOptions"
                  v-model="record.parameter_value"
                  @update:value="record.parameter_value = $event"
                />
              </CCol>
            </CRow>

            <!-- Domain nao informado -->
            <CRow v-else-if="parent.domain_id === null">
              <CCol sm="12" >
                <CInput
                  id="default_value"
                  label="Valor Default"
                  readonly
                  v-model="parent.default_value"
                />
              </CCol>
              <CCol sm="12" >
                <CInput
                  id="parameter_value"
                  label="Valor Cliente"
                  :readonly="readOnly"
                  v-model="record.parameter_value"
                />
              </CCol>
            </CRow>
          </template>
        </Detail>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import fn from "@/js"

import Toolbars from "@/components/Toolbars";
import Grid from "@/components/FormGrid";
import Detail from "@/components/FormDetail";
//import Filtrate from "./ComponentParameterTrailFilter";

export default {
  name: "ComponentParameterTrail",
  props: {
    parent: Object,
    trail: String
  },
  components: { Toolbars, Grid, Detail, /*Filtrate*/ },
  computed: {
    showFilter() {
      return this.$store.state.showFilter;
    },
    switchesIcon() {
      return this.$store.state.switchesIcon;
    },
    getCol() {
      return this.showFilter === false ? 12 : 12;
    },
  },
  data() {
    return {
      //Variaveis Fixas
      COMPONENT: {
        endPoint: "api/v01/internal/component-parameter-trail",
        primaryKey: "component_parameter_trail_id",
        descriptionKey: "component_parameter_trail_id",
        config: {
          child: true,
          parentName: "ComponentParameter", 
          parentKey: "component_parameter_id",
          parentValue: "component_parameter_id",
          parentService: "find-all-by-component-parameter",
          delete: { method: "delete", atribute: null, label: null },
          allowCrud: true,
          fields: 'default',
        },
        finds: []
      },
      //Variaveis Mutantes
      showGrid: true,
      readOnly: false,
      toolbars: {},
      filter: {},
      record: {},
      gridRowSelected: {},
      gridDbclick: {},
      fields: [],
      items: [],

      //Variaveis Componente <Select>
      defaultOptions: [],
    };
  },
  methods: {
    async init(){
      await this.$nextTick(() => {
        fn.initializeComponent(this)
        this.loadDefaultOptions(this.parent.domain_id)  
      })
      this.afterInit()
    },    
    afterInit() {
      this.loadData()
    },
    setInitialValues() {
      this.record.trail_id = this.trail
      fn.setAction(this)
    },  
    async loadDefaultOptions(payload) {
      await this.$nextTick(() => {
        this.defaultOptions = fn.finds.findValuesDomain(payload)
      }) 
    },
    loadData() {
      let param = {}
      param[`${this.COMPONENT.config.parentKey}`] = this.parent.parentValue
      param.trail_id = this.trail
      fn.getRecordsByMethod(this, param, this.COMPONENT.config.parentService) 
    }
  },
  watch: {
    toolbars: {
      immediate: true,
      handler(toolbars) {
        if(toolbars !== null && toolbars === 'add') {
          this.setInitialValues()
        } else if(toolbars !== undefined && toolbars !== null) {
          fn.setAction(this)
        }
        this.loadData()
      }
    },
    filter: {
      immediate: true,
      handler(filter) {
        if (Object.entries(filter).length !== 0) { 
          fn.getRecordsByFilters(this)    
        }
      }
    },
    gridRowSelected: {
      immediate: true,
      handler(gridRowSelected) {
        if (Object.entries(gridRowSelected).length !== 0) { 
          fn.loadRecord(this)    
        }
      }
    }, 
    parent: {
      immediate: true,
      handler(parent) {
        if(parent!== undefined && parent.parentKey === this.COMPONENT.config.parentKey) {
          this.init()
        } else {
          fn.reset(this)
        }
      }
    },
    defaultOptions: {
      immediate: true,
      handler(defaultOptions) {
        if(defaultOptions.length > 0) {
          defaultOptions.forEach(element => {
            element.value = String(element.value)
          });
        }
      }
    }
  }
}
</script>
